//colors
$darkblue: hsl(233, 26%, 24%);
$grayishblue: hsl(233, 8%, 62%);
$lightgrayishblue: hsl(220, 16%, 96%);
$limegreen: hsl(136, 65%, 51%);
$brightcyan: hsl(192, 70%, 51%);
$white: hsl(0, 0%, 100%);
$verylightgray: hsl(0, 0%, 98%);
$darkblue: hsl(233, 26%, 24%);
$teal: #00b4cf;
$lightgreen: #a7f0ba;
$firstC: hsla(174, 45%, 32%, 1);
$secondC: hsla(46, 98%, 48%, 1);

//font
$font-sm: 0.875rem; //14px
$font-md: 1.125rem; //18px
$font-lg: 1.25rem; //20px
$font-xl: 3.25rem;
