.features {
  .wrapper {
    color: black;
    .content {
      padding-top: 2rem;
      padding-bottom: 2rem;
      .features_grid {
        .inner {
          &_header {
            h1,
            p {
              text-align: center;
            }
            h1 {
              color: black;
              font-family: "Open Sans" sans-serif;
              font-size: 2.5rem;
              line-height: 2;
            }
            p {
              font-size: $font-md;
            }
          }
          .grid {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 3rem;
            padding: 0.5rem;
            .item {
              min-height: 200px;
              max-width: 350px;
              background: rgba($color: $white, $alpha: 1);
              border: 1px solid rgba($color: #000000, $alpha: 0.1);
              box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
              border-radius: 1rem;
              &:not(:last-child) {
                margin-bottom: 1rem;
              }
              &__wrapper {
                padding: 1rem;
                .icon {
                  width: calc(1.875rem + 2rem);
                  height: calc(1.875rem + 2rem);
                  padding: 1rem;
                  margin: auto;
                  margin-bottom: 2rem;
                  background: $firstC;
                  border-radius: 0.5rem;

                  span {
                    margin-left: auto;
                    margin-right: auto;
                    color: $white;
                    font-size: 1.875rem;
                  }
                }
                .title {
                  font-size: 2rem;
                  font-weight: 400;
                  font-family: "Poppins" sans-serif;
                  text-align: center;
                  color: $darkblue;
                  line-height: 1;
                }
                .desc {
                  text-align: center;
                  line-height: 1.5;
                }
              }
              @include breakpoint-up(medium) {
                max-width: 300px;
                margin-bottom: 1rem;
                margin-right: 1rem;
              }
              @include breakpoint-up(large) {
                min-width: 300px;
                margin-bottom: 1rem;
              }
            }
            @include breakpoint-up(medium) {
              width: 100%;
              flex-direction: row;
              justify-content: space-evenly;
              flex-wrap: wrap;
            }
            @include breakpoint-up(large) {
              width: 100%;
              flex-direction: row;
              justify-content: space-evenly;
            }
          }
        }
      }
    }
  }
}
.features_2 {
  margin-bottom: 4rem;
  background-image: url("../assets/images/about_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  .wrapper2 {
    .l__header {
      h2 {
        text-align: center;
        color: #000;
        font-family: "Open Sans" sans-serif;
        font-size: 2.8rem;
        line-height: 1.2;
      }
      p {
        font-size: $font-md;
        text-align: center;
        margin-bottom: 2rem;
        font-size: $font-md;
        color: #000;
        line-height: 1.5;
      }
    }
    .content2 {
      display: flex;
      flex-direction: column;
      padding: 3rem 0;
      .list {
        flex: 1;
        display: flex;
        flex-direction: column;
        .l_item {
          display: block;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          padding: 1.5rem;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          color: #000;
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
          &_wrap {
            display: flex;
            flex-direction: row;
            align-items: center;

            .iconbox {
              min-width: 3rem;
              height: 3rem;
              margin-right: 1rem;
              background-image: url("../assets/images/promo-icon-bg.svg");
              background-size: contain;
              background-position: center center;
            }
          }
        }
      }
      .illustration {
        width: 100%;
        flex: 1;
        padding: 6rem;
      }
    }
  }
}

@media only screen and (min-width: 30.0625rem) and (max-width: 48rem) {
  .content2 {
    flex-direction: column;
    .list {
      flex: 2 !important;
      width: 80%;
      margin: auto;
      .l_item {
      }
    }
    .illustration {
      flex: 1 !important;
    }
  }
}
@media only screen and (min-width: 48.0625rem) and (max-width: 64rem) {
  .content2 {
    flex-direction: row !important;
    .list {
      flex: 2 !important ;
      .l_item {
        width: 80% !important;
      }
    }
    .illustration {
      flex: 1 !important ;
      img {
      }
    }
  }
}

@media only screen and (min-width: 64.0625rem) and (max-width: 75rem) {
  .content2 {
    flex-direction: row !important;
    .list {
      flex: 2 !important ;
      .l_item {
        width: 80% !important;
      }
    }
    .illustration {
      flex: 1 !important ;
      img {
      }
    }
  }
}
@media only screen and (min-width: 75.0625rem) {
  .content2 {
    flex-direction: row !important;
    .list {
      flex: 2 !important ;
      .l_item {
        width: 80% !important;
      }
    }
    .illustration {
      flex: 1 !important ;
      img {
      }
    }
  }
}
