.pheader {
  width: 100vw;
  position: fixed;
  z-index: 100;
  @include breakpoint-up(medium) {
    background: $firstC;
  }
  @include breakpoint-up(large) {
    background: $firstC;
  }
  &__wrapper {
    width: 100%;
  }
  &__content {
    width: 100%;
    background-color: $firstC;
    @include breakpoint-up(large) {
    }
    nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      .logo {
        font-family: 'Open Sans', sans-serif;
        font-size: $font-md;
        font-weight: 500;
        line-height: 1;
        color: $white;
      }
      .hamburger {
        span {
          background: $white;
        }
      }
      a {
        font-family: 'Open Sans', sans-serif;
        font-size: $font-sm;
        color: #fff;
        transition: color 200ms ease-in-out;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &:hover,
        &.active {
          color: rgba($secondC, 1);
        }
      }
    }
  }
  &__cta {
    a {
      text-transform: uppercase;
      font-family: 'Open Sans', sans-serif;
      font-size: $font-sm;
      font-weight: 300;
      padding: 0.75rem;
      border-radius: 0.625rem;
      background: linear-gradient(
        to right bottom,
        rgba($darkblue, 1),
        rgba($darkblue, 0.7)
      );
      color: $white !important;
      transition: background 200ms ease-in-out;
      &:hover {
        background: $darkblue;
      }
    }
  }
  &__params {
    span {
      display: inline-block;
      a {
        padding: 0.2rem 0;
        color: rgba($white, 1);
        font-size: 1rem;
        display: flex;
        align-items: flex-end;
        span {
          font-size: $font-sm;
          margin-left: 0.5rem;
        }
      }
      &:not(last-child) {
        margin-right: 1.5rem;
      }
    }
  }
  .mobile_menu {
    height: calc(100vh - 3.09rem);
    background: rgba($color: #fff, $alpha: 1);
    width: calc(100vw - 00.75rem);
    position: absolute;
    left: -100%;
    z-index: 100;
    .wrapper {
      padding: 1rem 0.75rem;
      height: 100%;
      width: 100%;
      .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        .top {
          justify-self: flex-start;
          width: 100%;
          .menu {
            a {
              display: block;
              width: 100%;
              padding: 1rem 0;
              font-family: 'Open Sans' sans-serif;
              font-size: 1.5rem;
              color: black;
			  &:not(:last-child)
			  {
				  border-bottom: 1px solid rgba($color: $secondC, $alpha: .2);
			  }
            }
          }
        }
        .bottom {
          justify-self: flex-end;
          position: absolute;
          bottom: 0;
          width: 100%;
          .params {
            padding: 0.5rem 0;
            width: 100%;
            span {
              display: block;
              a {
                padding: 0.2rem 0;
                color: rgba($darkblue, 1);
                font-size: 1.5rem;
                display: flex;
                align-items: flex-end;
                span {
                  font-size: $font-md;
                  margin-left: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
    &.show {
      left: 0;
    }
  }
}
