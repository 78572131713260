.hamburger {
  span {
    display: block;
    width: 26px;
    height: 2px;
    background-color: $darkblue;
    transition: all 300ms ease-in-out;
    transform-origin: 3px 1px;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  &.open {
    > span:first-child {
      transform: rotate(45deg);
    }
    > span:nth-child(2) {
      opacity: 0;
    }
    > span:last-child {
      transform: rotate(-45deg);
    }
  }
}
