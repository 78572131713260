.hero {
  &__wrapper {
    background: $firstC;
    position: relative;
  }
  &__content {
    position: relative;
    z-index: 1;
    .row {
      max-height: 100%;
    }
  }

  &__illustration {
    order: 1;
    display: flex;
    justify-content: center;
    background-image: url('../assets/images/blob.svg');
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center center;
    margin-top: 4rem;
    img {
      max-height: 15.625rem;
    }
  }

  .texte__wrapper {
    order: 2;
    margin-top: 2rem;
    text-align: center;
  }

  &__texte {
    .texte_inner {
      flex-direction: column;
      text-align: center;
      color: $white;

      h1 {
        font-weight: 400;
        font-size: 2.25rem;
        line-height: 1.5;
        color: $white;
        margin-bottom: 1.5rem;
      }
      p {
        line-height: 1.5;
        margin-bottom: 1.5;
        font-size: $font-md;
        color: $white;
      }
      .cta {
        width: 100%;
        margin-top: 2rem;
        min-height: 7rem;
        a {
          padding: 1rem 1.5rem;
          font-family: 'Open Sans' sans-serif;
          line-height: 1;
          font-weight: 500;
          cursor: pointer;

          &:not(:last-child) {
            margin-right: 0.5rem;
            background: #000;
            color: #fff;
            border-radius: 0.5rem;
            border: 0.1rem solid rgba($color: #000, $alpha: 1);
          }
          &:last-child {
            margin-right: 0.5rem;
            background: #fff;
            color: #000;
            border: 0.1rem solid rgba($color: #000000, $alpha: 1);
            border-radius: 0.5rem;
          }
        }
      }
    }
  }
  &__bottom {
    position: absolute;
    top: 0;
    bottom: -1%;
    left: 0;
    right: 0;
    z-index: 0;
    div {
      display: flex;
      height: 100%;
      width: 100%;
      img {
        align-self: flex-end;
      }
    }
  }
}
@media only screen and (min-width: 30.0625rem) and (max-width: 48rem) {
  .hero {
    // height: 25rem;
    &__wrapper {
      display: flex;
    }
    &__content {
    }
    &__illustration {
      order: 2;
      display: flex;
      justify-content: center;
      background-image: url('../assets/images/blob.svg');
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center center;

      img {
        margin-top: 4rem;
        max-height: 20rem;
      }
    }
    .texte__wrapper {
      order: 1;
      margin-top: 2rem;
      text-align: center;
    }
    &__texte {
      .texte_inner {
        flex-direction: column;
        color: $white;
        margin-top: 4rem;
        text-align: left;
        h1 {
          font-weight: 400;
          font-size: 2rem;
          line-height: 1.5;
          color: $white;
          margin-bottom: 1.5rem;
        }
        p {
          line-height: 1.5;
          margin-bottom: 1.5;
          font-size: $font-md;
          color: $white;
        }
        .cta {
          width: 100%;
          margin-top: 2rem;
          min-height: 7rem;
          a {
            padding: 1rem 1.5rem;
            font-family: 'Open Sans' sans-serif;
            line-height: 1;
            font-weight: 500;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 0.5rem;
              background: #000;
              color: #fff;
              border-radius: 0.5rem;
              border: 0.1rem solid rgba($color: #000, $alpha: 1);
            }
            &:last-child {
              margin-right: 0.5rem;
              background: #fff;
              color: #000;
              border: 0.1rem solid rgba($color: #000000, $alpha: 1);
              border-radius: 0.5rem;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 48.0625rem) and (max-width: 64rem) {
  .hero {
    height: 35rem;
    &__wrapper {
      height: 100%;
      display: flex;
    }
    &__content {
    }
    &__illustration {
      order: 2;
      display: flex;
      justify-content: center;
      background-image: url('../assets/images/blob.svg');
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center center;

      img {
        margin-top: 4rem;
        max-height: 20rem;
      }
    }
    .texte__wrapper {
      order: 1;
    }
    &__texte {
      .texte_inner {
        flex-direction: column;
        color: $white;
        margin-top: 4rem;
        text-align: left;
        h1 {
          font-weight: 400;
          font-size: 2.5rem;
          line-height: 1.5;
          color: $white;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: $font-md;
          line-height: 1.5;
          margin-bottom: 1.5;
          font-size: $font-md;
          color: $white;
        }
        .cta {
          width: 100%;
          margin-top: 2rem;
          min-height: 7rem;
          a {
            padding: 1rem 1.5rem;
            font-family: 'Open Sans' sans-serif;
            line-height: 1;
            font-weight: 500;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 0.5rem;
              background: #000;
              color: #fff;
              border-radius: 0.5rem;
              border: 0.1rem solid rgba($color: #000, $alpha: 1);
            }
            &:last-child {
              margin-right: 0.5rem;
              background: #fff;
              color: #000;
              border: 0.1rem solid rgba($color: #000000, $alpha: 1);
              border-radius: 0.5rem;
            }
          }
        }
      }
    }
    &__bottom {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
      div {
        display: flex;
        height: 100%;
        width: 100%;
        img {
          align-self: flex-end;
        }
      }
    }
  }
}
@media only screen and (min-width: 64.0625rem) and (max-width: 75rem) {
  .hero {
    height: 45rem;
    &__wrapper {
      height: 100%;
      display: flex;
    }
    &__content {
    }
    &__illustration {
      order: 2;
      display: flex;
      justify-content: center;
      background-image: url('../assets/images/blob.svg');
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center center;

      img {
        margin-top: 4rem;
        max-height: 40rem;
      }
    }
    .texte__wrapper {
      order: 1;
    }
    &__texte {
      .texte_inner {
        flex-direction: column;
        color: $white;
        margin-top: 4rem;
        text-align: left;
        h1 {
          font-weight: 400;
          font-size: 2.5rem;
          line-height: 1.5;
          color: $white;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: $font-md;
          line-height: 1.5;
          margin-bottom: 1.5;
          font-size: $font-md;
          color: $white;
        }
        .cta {
          width: 100%;
          margin-top: 2rem;
          min-height: 7rem;
          a {
            padding: 1rem 1.5rem;
            font-family: 'Open Sans' sans-serif;
            line-height: 1;
            font-weight: 500;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 0.5rem;
              background: #000;
              color: #fff;
              border-radius: 0.5rem;
              border: 0.1rem solid rgba($color: #000, $alpha: 1);
            }
            &:last-child {
              margin-right: 0.5rem;
              background: #fff;
              color: #000;
              border: 0.1rem solid rgba($color: #000000, $alpha: 1);
              border-radius: 0.5rem;
            }
          }
        }
      }
    }
    &__bottom {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
      div {
        display: flex;
        height: 100%;
        width: 100%;
        img {
          align-self: flex-end;
        }
      }
    }
  }
}
@media only screen and (min-width: 75.0625rem) {
  .hero {
    height: 45rem;
    &__wrapper {
      height: 100%;
      display: flex;
    }
    &__content {
    }
    &__illustration {
      order: 2;
      margin-top: 6rem;
      display: flex;
      justify-content: center;
      background-image: url('../assets/images/blob.svg');
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center center;

      img {
        max-height: 35rem;
      }
    }
    .texte__wrapper {
      order: 1;
    }
    &__texte {
      margin-top: 8rem;
      .texte_inner {
        text-align: left;
        flex-direction: column;
        color: $white;
        margin-top: 4rem;

        h1 {
          font-weight: 400;
          font-size: 4rem;
          line-height: 1.2;
          color: $white;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: $font-md;
          line-height: 1.5;
          margin-bottom: 1.5;
          font-size: $font-md;
          color: $white;
        }
        .cta {
          width: 100%;
          margin-top: 2rem;
          min-height: 7rem;
          a {
            padding: 1rem 1.5rem;
            font-family: 'Open Sans' sans-serif;
            line-height: 1;
            font-weight: 500;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 0.5rem;
              background: #000;
              color: #fff;
              border-radius: 0.5rem;
              border: 0.1rem solid rgba($color: #000, $alpha: 1);
            }
            &:last-child {
              margin-right: 0.5rem;
              background: #fff;
              color: #000;
              border: 0.1rem solid rgba($color: #000000, $alpha: 1);
              border-radius: 0.5rem;
            }
          }
        }
      }
    }
    &__bottom {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 0;
      div {
        display: flex;
        height: 100%;
        width: 100%;
        img {
          align-self: flex-end;
        }
      }
    }
  }
}

// .hero {
//   background: $firstC;
//   height: 100vh;
//   @media (min-width: 33.76rem) {
//     height: 100vh;
//   }
//   @media (min-width: 48rem) {
//     height: 40rem;
//   }
//   @media (min-width: 65rem) {
//     height: 43.125rem;
//   }

//   &__wrapper {
//     width: 100%;
//     z-index: 1;
//     height: 100%;
//   }
//   &__content {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//     height: 100%;
//     @media (min-width: 48rem) {
//       flex-direction: row;
//       height: 100%;
//     }
//     @media (min-width: 65rem) {
//       flex-direction: row;
//       height: 100%;
//     }
//   }
//   &__texte {
//     width: 100%;
//     position: relative;
//     text-align: center;
//     padding: 0 1rem;
//     z-index: 1;
//     .inner__wrapper {
//       h1 {
//         font-weight: 300;
//         font-size: 2.31rem;
//         line-height: 1.5;
//         color: $white;
//         margin-bottom: 1.5rem;
//       }
//       p {
//         line-height: 1.5;
//         margin-bottom: 1.5;
//         font-size: $font-md;
//         color: $white;
//       }
//       .cta {
//         width: 100%;
//         margin-top: 2rem;
// 		min-height: 7rem;
//         a {
//           padding: 0.5rem 1rem;
//           font-family: 'Open Sans' sans-serif;
//           line-height: 1;
//           font-weight: 500;
//           cursor: pointer;

//           &:not(:last-child) {
//             margin-right: 0.5rem;
//             background: #000;
//             color: #fff;
//             border-radius: 0.5rem;
//             border: 0.1rem solid rgba($color: #000, $alpha: 1);
//           }
//           &:last-child {
//             margin-right: 0.5rem;
//             background: #fff;
//             color: #000;
//             border: 0.1rem solid rgba($color: #000000, $alpha: 1);
//             border-radius: 0.5rem;
//           }
//         }
//       }
//     }
//     @media (min-width: 65rem) {
//       order: 1;
//       flex: 1;
//       text-align: left;
//     }
//     @media (min-width: 33.76rem) {
//       order: 1;
//       flex: 1;
//       text-align: left;
//     }
//   }
//   &__image {
//     position: relative;
// 	min-height: 10.5rem;
//     margin-top: 2rem;
//     width: 100%;
//     margin-bottom: 2rem;
//     z-index: 1;
//     @media (min-width: 33.76rem) {
//       min-height: 17.5rem;
//     }
// 	@media (min-width: 25.876rem) {
// 		min-height: 13rem;
// 	  }
//     &::before {
//       content: '';
//       position: absolute;
//       height: 100%;
//       width: 100%;
//       background-image: url('../assets/images/blob.svg');
//       background-repeat: no-repeat;
//       background-position: center;
//       background-size: contain;
//       @media (min-width: 33.76rem) {
//         background-size: 40%;
//       }
// 	  @media (min-width: 48rem) {
//         background-size: contain;
//       }
//     }
//     &::after {
//       content: '';
//       position: absolute;
//       height: 100%;
//       width: 100%;
//       background-image: url('../assets/images/daily-bread-app-uat.web.app_tabs_home\(iPhone\ 6_7_8\)\ \(3\).png');
//       background-repeat: no-repeat;
//       background-size: contain;
//       background-position: center;
//       @media (min-width: 33.76rem) {
//         background-size: 40%;
//       }
//     }
//     @media (min-width: 65rem) {
//       order: 2;
//       flex: 1;
//       height: 70%;
//     }
//     @media (min-width: 33.76rem) {
//       order: 2;
//       flex: 1;
//       height: 70%;
//       margin-top: 0;
//     }
//   }
// }
// .hero_top {
//   z-index: 0;
//   display: block;
//   width: 100%;
//   position: relative;
//   @media (min-width: 65rem) {
//     margin-top: -30%;
//   }
//   @media (max-width: 64rem) {
//     top: -48%;
//   }
//   @media (max-width: 48rem) {
//     top: -35%;
//   }
//   @media (max-width: 33.75rem) {
//     top: -22%;
//   }
//   @media (max-width: 25.875rem) {
//     top: -17%;
//   }
//   @media (max-width: 24rem) {
//     top: -14%;
//   }
// }
