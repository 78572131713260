.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes scroll-in {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes scroll-out {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

.scroll-in {
  animation: scroll-in 0.75s forwards;
}
.scroll-out {
  animation: scroll-out 0.75s forwards;
}
.fade-in {
  animation: fade-in 300ms ease-in-out forwards;
}

.fade-out {
  animation: fade-out 300ms ease-in-out forwards;
}
