.settings,
.politics {
  padding-top: 4rem;
  &__title {
    color: black;
    h2 {
      line-height: 1.5;
      font-family: "Open Sans" sans-serif;
    }
  }
  &__content {
  }
  &__text {
	  color: $white;
    p {
      color: #fff;
    }
  }
}

.accordion__section {
  display: flex;
  flex-direction: column;
  .accordion {
    background-color: #eee;
    color: $firstC;
    cursor: pointer;
    padding: 1.2rem;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    transition: background-color 0.6s ease;
    &:not(last-child) {
      margin-bottom: 1rem;
    }
    &:hover,
    &.active {
      background-color: $secondC;
      color: black;
    }
    &__title {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .accordion__content {
    background-color: $white;
    overflow: hidden;
    transition: max-height 0.6s ease;
    .accordion__text {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: $font-sm;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  }
}

/* Style the accordion content title */

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}
