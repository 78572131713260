.contact {
  .wrapper {
    .content {
      .contact_inner {
        flex-direction: column;
        width: 100%;
        @include breakpoint-up('large') {
          display: flex;
          flex-direction: row;
        }
        @include breakpoint-up('medium') {
          display: flex;
          flex-direction: row;
        }
        .left {
          background: $firstC;
          color: $white;
          text-align: center;
          padding: 2rem;
          margin-bottom: 4rem;
          @include breakpoint-up(large) {
            flex: 1;
          }
          @include breakpoint-up(medium) {
            flex: 1;
          }
          .item {
            h2 {
              font-size: 2rem;
              font-weight: 400;
            }
            p {
              font-size: $font-sm;
              font-weight: 300;
              span:first-child {
                margin-right: 0.5rem;
              }
            }
            .icon {
              margin: 0 0.25rem;
              color: $secondC;
              svg {
                height: 2rem;
                width: 2rem;
              }
            }
          }
        }
        .right {
          @include breakpoint-up(large) {
            flex: 1;
          }
          @include breakpoint-up(medium) {
            flex: 1;
          }
          margin-bottom: 4rem;
          padding: 2rem;
          background: rgba($color: #000000, $alpha: 0.1);
          form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            width: 100%;
            margin: auto;

            h2 {
              color: $secondC;
              text-align: center;
            }
            span {
              font-size: $font-sm;
            }
            input,
            textarea {
              background: #eee;
              border: none;
              padding: 12px 15px;
              margin: 0.5rem 0;
              width: 100%;
              font-size: 0.8rem;
              &:focus {
                outline: 0.0625rem solid $secondC;
              }
            }
            textarea {
              min-height: 4rem;
            }
            button {
              border-radius: 2px;
              border: 1px solid $secondC;
              background: $secondC;
              color: $white;
              font-size: 0.75rem;
              font-weight: 400;
              padding: 0.75rem 0.9375rem;
              letter-spacing: 0.0625rem;
              text-transform: uppercase;
              transition: transform 80ms ease-in-out;
              cursor: pointer;
              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
}
