// Breakpoints
@media (min-width: 64em) {
}

// 640px, 1024px et 1400px
$breakpoint-up: (
  "medium": "40em",
  "large": "64em",
  "xlarge": "87.5em",
);

// 639px, 1023px, 1399 px
$breakpoint-down: (
  "small": "39.9375rem",
  "medium": "63.937rem",
  "large": "87.4375em",
);

$breakpoints:(
	"xsamll": "",
	"small": "",
	"medium":"63.9375",
	"large":"64rem",
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoint-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoint-down, $size)) {
    @content;
  }
}

@mixin breakpoints($size)
{

}
