.teams {
  margin-top: 2rem;
  margin-bottom: 2rem;
  .wrapper {
    .content {
      .teams_header {
       
      }
    }
  }
}
