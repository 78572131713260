@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-family: 'Poppins', sans-serif;
  color: $grayishblue;
  font-size: $font-md;
  font-weight: 300;

  overflow-x: hidden;
  &.noscroll {
    overflow: hidden;
  }
  @include breakpoint-up(large) {
    font-size: $font-md;
  }
}

h1,
h2,
h3 {
  margin-top: 0;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}
a:hover {
  cursor: pointer;
}
//flexbox style
.flex {
  display: flex;
  &-jc-sb {
    justify-content: space-between;
  }
  &-jc-c {
    justify-content: center;
  }
  &-jc-e {
    justify-content: space-evenly;
  }
  &-jc-a {
    justify-content: space-around;
  }
  &-ai-c {
    align-items: center;
  }
}

//visibility
.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}

// spacing
.ccontainer {
  max-width: 69.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin: 0 auto;
  @include breakpoint-up(large) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @include breakpoint-up(medium) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &.px {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  &.py {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  &.pt {
    padding-top: 2.25rem;
  }
  &.pl {
    padding-left: 1.5rem;
  }
  &.pr {
    padding-right: 1.5rem;
  }
  &.pb {
    padding-bottom: 2.25rem;
  }
}

.footer {
  background: $firstC;
  position: relative;
  bottom: 0;
  color: #000;
  height: 8rem;
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $font-sm;
    width: 100%;
    text-align: center;
  }
  .f_bottom {
    width: 100%;
    img {
      position: relative;
      top: -.2rem;
      max-width: 100%;
      height: auto;
    }
    @include breakpoint-up(large) {
      margin-top: 2rem;
      height: 3rem;
      background-position: bottom;
    }
  }
}
